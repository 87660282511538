import React from "react";

interface BotDetectionProps {
  enabled: boolean;
  sitekey: string;
  callback: (botDetectionToken: string) => void;
}

declare global {
  interface Window {
    turnstile: any;
    onloadTurnstileCallback: () => void;
  }
}

export const BotDetection: React.FC<BotDetectionProps> = ({
  enabled = false,
  sitekey,
  callback,
}) => {
  window.onloadTurnstileCallback = () => {
    if (enabled) {
      window.turnstile.render("#bot-detection", {
        sitekey: sitekey,
        callback: callback,
      });
    }
  };

  return (
    <div>
      {enabled && (
        <div id="bot-detection" data-role="bot-detection-btn" data-testid="bot-detection"></div>
      )}
    </div>
  );
};
