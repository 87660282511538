import { useLayoutEffect } from "react";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";

import Callback from "./pages/auth0/callback";
import LoggedOut from "./pages/logged-out/LoggedOut";
import Identifier from "./pages/login/identifier";
import Password from "./pages/login/password";
import { Logout } from "./pages/logout/Logout";
import { ConfirmUnifiedAccountLink } from "./pages/unified_account_linking/ConfirmUnifiedAccountLink";
import { PromptUnifiedAccountLink } from "./pages/unified_account_linking/PromptUnifiedAccountLink";
import Start from "./pages/unified_login/start";
import { SendGridSessionContext, useSendGridSession } from "./sendgrid-auth";
import { useUnifiedAuth } from "./unified-auth";

const botDetectionEnabled = process.env.REACT_APP_BOT_DETECTION_ENABLED === "true";
const botDetectionSitekey =
  process.env.REACT_APP_BOT_DETECTION_SITEKEY || "1x00000000000000000000AA";
const enabledUnifiedAccountLinking =
  process.env.REACT_APP_ENABLE_UNIFIED_ACCOUNT_LINKING === "true";

const LoginRoutes = () => {
  const { navigateToGuide } = useUnifiedAuth();

  // If mako token is already set just navigate to the guide because it means you've already logged in.
  // This requires a useEffect because it causes an infinite recursion if it is not in one.
  // Also, we do not want it to fire if we change the makoToken, once we move all security pages into
  // login we can wait to set the mako token to the end of on login flow and then have this automatically redirect
  // us to the guide by watching for the mako token update.
  //
  useLayoutEffect(() => {
    (async () => {
      await navigateToGuide();
    })();
    //eslint-disable-next-line
  }, []);

  return (
    <Routes>
      <Route index element={<Navigate to="/login/identifier" replace />} />
      <Route path="/login" element={<Navigate to="/login/identifier" replace />} />
      <Route path="/login/password" element={<Password />} />
      <Route
        path="/login/identifier"
        element={
          <Identifier
            botDetectionEnabled={botDetectionEnabled}
            botDetectionSitekey={botDetectionSitekey}
          />
        }
      />
    </Routes>
  );
};

const UnifiedAccountLinkingRoutes = () => {
  const { goToLoginIfNotAuthenticatedWithSendgrid } = useUnifiedAuth();
  const { isAuthenticated: isAuthenticatedWithSendGrid } =
    useSendGridSession(SendGridSessionContext);

  useLayoutEffect(() => {
    goToLoginIfNotAuthenticatedWithSendgrid();
    //eslint-disable-next-line
  }, []);

  if (!isAuthenticatedWithSendGrid) {
    return <></>;
  }

  return (
    <Routes>
      <Route path="/prompt" element={<PromptUnifiedAccountLink />} />
      <Route path="/confirm" element={<ConfirmUnifiedAccountLink />} />
    </Routes>
  );
};

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/auth0/callback" element={<Callback />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/logged-out" element={<LoggedOut />} />
        <Route path="/unified_login/start" element={<Start />} />
        <Route path="*" element={<LoginRoutes />} />
        {enabledUnifiedAccountLinking && (
          <Route path="/unified_account_linking/*" element={<UnifiedAccountLinkingRoutes />} />
        )}
      </Routes>
    </>
  );
};

export default AppRoutes;
