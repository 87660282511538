import {
  AppState,
  Auth0Context,
  Auth0ContextInterface,
  Auth0Provider,
  AuthorizationParams,
  GetTokenSilentlyOptions,
  RedirectLoginOptions,
  useAuth0,
  User,
} from "@auth0/auth0-react";
import React, {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { UnifiedAuthEvents } from "../analytics";
import { websiteContext } from "../context";
import { useExternalNavigation } from "../external-navigate";
import { wait } from "../helpers";
import { useExternalNavigate } from "../hooks/useExternalNavigate";
import {
  SendGridRouterContext,
  SendGridRouterContextInterface,
  SendGridRouterProvider,
  SendGridSessionContext,
  SendGridSessionContextInterface,
  SendGridSessionProvider,
  useSendGridRouter,
  useSendGridSession,
} from "../sendgrid-auth";
import { Analytics, Identity, NoopAnalytics, TrackEvent } from "./analytics";
import { LOGOUT_REDIRECT_TO, UnifiedProvisionError, UnifiedProvisionPlanError } from "./constants";
import {
  Auth0RedirectLoginOptions,
  ConfirmAccountLinkingResult,
  initialUnifiedAuthState,
  LogoutOptions,
  NavigateOptions,
  UnifiedAccountLinkType,
  UnifiedAuthContext,
  UnifiedAuthContextInterface,
  UnifiedProvisionEligibilityResult,
} from "./context";
import { bannerStatus, FetchError, loginError } from "./error";
import {
  deleteAuthenticateToken,
  fetchAuthenticateToken,
  FetchAuthenticateTokenResponse,
  fetchIdentifier,
  fetchSessionToken,
  fetchSSO,
  FetchSSOResponse,
  FetchTwoFAResponse,
  fetchTwoFASetting,
  fetchUnifiedProvision,
  fetchUnifiedProvisionPlan,
  fetchUnifiedSearch,
  FetchUnifiedSearchResponse,
  fetchUnifiedSignup,
} from "./fetch";
import { useSessionUsername, useUnifiedUserInfoCookie } from "./hooks";
import { reducer } from "./reducer";
import { acceptTeammateInvite, stripUndefined } from "./utils";

/**
 * UseAuthenticateOptions uses authenticate options
 */
export interface UnifiedAuthProviderOptions {
  children: React.ReactNode;
  sendGridSessionContext?: React.Context<SendGridSessionContextInterface>;
  sendGridRouterContext?: React.Context<SendGridRouterContextInterface>;
  auth0Context?: React.Context<Auth0ContextInterface>;
  context?: React.Context<UnifiedAuthContextInterface>;

  // Auth0 Domain info
  domain: string;
  clientId: string;
  audience: string;

  // Store analytics information.
  analytics?: Analytics;

  // Proxy Domain info for Authorize call
  proxyDomainForAuthorize?: string;

  // Unified Account Linking configurations
  enableUnifiedAccountLinking?: boolean;
  unifiedAccountLinkingRolloutPhase?: number;
  unifiedAccountLinkingRolloutBase?: number;
}

/**
 * This is the actual provider, we split between this and the impl because we want to test by passing context map.
 * @param children
 * @param props
 * @constructor
 */
export const UnifiedAuthProvider = ({
  children,
  ...props
}: PropsWithChildren<UnifiedAuthProviderOptions>) => {
  const { domain, clientId, audience } = props;
  const ref = useRef<UnifiedAuthContextInterface>(null);

  const onRedirectCallback = (appState?: AppState, user?: User) => {
    (async () => {
      if (ref.current !== null) {
        //
        await ref.current.handleRedirectCallback(appState, user);
      }
    })();
  };
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri:
          (typeof window !== "undefined" && `${window.location.origin}/auth0/callback`) || "",
      }}
      onRedirectCallback={onRedirectCallback}>
      <SendGridSessionProvider>
        <SendGridRouterProvider>
          <UnifiedAuthProviderImpl ref={ref} {...props}>
            {children}
          </UnifiedAuthProviderImpl>
        </SendGridRouterProvider>
      </SendGridSessionProvider>
    </Auth0Provider>
  );
};

/**
 * We don't export this because it keeps the united logic of SendGrid's approach to log-in/logout and Auth0 unified
 * log-in/logout.
 */
export const UnifiedAuthProviderImpl = forwardRef(
  (opts: UnifiedAuthProviderOptions, ref): JSX.Element => {
    const {
      children,
      domain,
      audience,
      sendGridSessionContext = SendGridSessionContext,
      sendGridRouterContext = SendGridRouterContext,
      auth0Context = Auth0Context,
      context = UnifiedAuthContext,
      enableUnifiedAccountLinking = false,
      proxyDomainForAuthorize = "",
      analytics = NoopAnalytics, // By default we don't do anything with analytics this makes testing easier.
      unifiedAccountLinkingRolloutPhase = 1,
      unifiedAccountLinkingRolloutBase = 1,
    } = opts;
    const {
      loginWithRedirect,
      getAccessTokenSilently,
      logout: logoutWithAuth0,
      isAuthenticated: isAuthenticatedWithAuth0,
      isLoading: isLoadingWithAuth0,
      user: twilioAuth0User,
    } = useAuth0(auth0Context);
    const {
      makoToken,
      setSession,
      clearSession,
      isAuthenticated: isAuthenticatedWithSendGrid,
      navigationOptions,
    } = useSendGridSession(sendGridSessionContext);
    const { navigate: navigateToMako } = useSendGridRouter(sendGridRouterContext);
    const externalNavigate = useExternalNavigate();
    const reactRouterNavigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialUnifiedAuthState);
    const { setUnifiedUserInfo, removeUnifiedUserInfo } = useUnifiedUserInfoCookie();
    const [, setSessionUsername, removeSessionUsername] = useSessionUsername();
    const { store } = useExternalNavigation(websiteContext);

    const identify = useCallback(
      async (identity: Identity) => {
        try {
          await analytics.identify(identity);
        } catch (error: any) {
          console.error("Failed to identify user", error);
        }
      },
      [analytics]
    );

    const track = useCallback(
      async (data: TrackEvent) => {
        try {
          analytics.track(data);
        } catch (error: any) {
          console.error("Failed to track event", error);
        } finally {
          // Generally used to give the browser time to make outbound requests
          // (send the events) before a redirect. We want to run this even if
          // tracking fails because tracking is non critical behavior.
          await wait(300);
        }
      },
      [analytics]
    );

    // In order to support a proxy domain for the authorize endpoint we need to provide a different
    // openUrl function to the auth0 loginWithRedirect function.
    const setUnifiedUserInformationInCookie = useCallback(
      (user: User | undefined) => {
        const { given_name = "", family_name = "", email = "" } = user ?? {};
        setUnifiedUserInfo({
          given_name,
          family_name,
          email,
        });
      },
      [setUnifiedUserInfo]
    );

    // In order to support a proxy domain for the authorize endpoint we need to provide a different
    // openUrl function to the auth0 loginWithRedirect function.
    const openUrl = useCallback(
      async (urlWithFragment: string) => {
        // If we have a proxy domain for authorize we need to replace the existing domain with the proxy domain
        if (proxyDomainForAuthorize) {
          urlWithFragment = urlWithFragment.replace(domain, proxyDomainForAuthorize);
        }
        window.location.assign(urlWithFragment);
      },
      [proxyDomainForAuthorize, domain]
    );

    const _prepareLoginWithRedirect = useCallback(
      (opts: Auth0RedirectLoginOptions): RedirectLoginOptions<AppState> => {
        const {
          email = "",
          screen_hint,
          token,
          fromUnifiedAccountLink,
          additionalAuthorizationParams,
        } = opts;
        const appState: AppState = stripUndefined({
          screenHint: screen_hint,
          token: token,
          fromUnifiedAccountLink: fromUnifiedAccountLink,
        });
        const authorizationParams: AuthorizationParams = stripUndefined({
          audience: audience,
          login_hint: email,
          redirect_uri:
            (typeof window !== "undefined" && `${window.location.origin}/auth0/callback`) || "",
          screen_hint: screen_hint,
          ...additionalAuthorizationParams,
        });
        return {
          appState: appState,
          authorizationParams: authorizationParams,
          openUrl: openUrl,
        };
      },
      [audience, openUrl]
    );

    /**
     * This wraps the Auth0 loginWithRedirect function to clear the session before logging in.
     */
    const loginWithAuth0 = useCallback(
      async (opts: Auth0RedirectLoginOptions = {}) => {
        const { email = "", fromUnifiedAccountLink } = opts;

        if (!fromUnifiedAccountLink) {
          // prevent removal of mako token when coming from unified account linking
          clearSession();
        }

        await track(UnifiedAuthEvents.Auth0LoginStart(email));

        let options = _prepareLoginWithRedirect(opts);

        return loginWithRedirect(options);
      },
      [loginWithRedirect, clearSession, track, _prepareLoginWithRedirect]
    );

    const fetchUnifiedAccountLinkingEligibility = useCallback(
      async (makoToken: string): Promise<UnifiedProvisionEligibilityResult> => {
        const response = await fetchUnifiedProvisionPlan(makoToken);
        if (!response.eligible) {
          if (response.reason) {
            return {
              eligible: false,
              error: response.reason as UnifiedProvisionPlanError,
            };
          }
          return {
            eligible: false,
          };
        }
        const eligibleByPreviousRolloutPhase =
          response.rollout_phase < unifiedAccountLinkingRolloutPhase;

        const eligibleByCurrentRolloutPhase =
          response.rollout_phase === unifiedAccountLinkingRolloutPhase &&
          response.base <= unifiedAccountLinkingRolloutBase;

        if (!eligibleByPreviousRolloutPhase && !eligibleByCurrentRolloutPhase) {
          return {
            eligible: false,
          };
        }
        return {
          eligible: true,
          email: response.email,
        };
      },
      [unifiedAccountLinkingRolloutBase, unifiedAccountLinkingRolloutPhase]
    );

    const loginWithBasicAuth = useCallback(
      async (username: string, password: string) => {
        dispatch({ type: "LOGIN_WITH_IDENTIFIER_AND_PASSWORD_STARTED" });
        try {
          const authenticateResponse = await fetchAuthenticateToken(username, password);
          const authenticateBody: FetchAuthenticateTokenResponse =
            await authenticateResponse.json();
          if (authenticateResponse.status === 401) {
            // send invalid login attempt to segment and datadog
            await track(UnifiedAuthEvents.FailedLoginAttemptBasic(username));
          }
          if (!(authenticateResponse.status === 200 || authenticateResponse.status === 201)) {
            throw new FetchError("failure to fetch authenticate token", {
              status: authenticateResponse.status,
              errors: authenticateBody.errors,
            });
          }

          const token = authenticateBody.token ?? "";
          const passwordResetRequired = authenticateBody.password_reset_required ?? false;
          const setup2FARequired = authenticateBody.setup_2fa_required ?? false;

          // If the user is not required to set up 2FA, we need to determine
          // if they already have 2FA enabled or are just exempt from the requirement.
          // If they don't have 2FA enabled, then check if they need to reset their password.
          let twoFAResponse = await fetchTwoFASetting(token);
          let twoFABody: FetchTwoFAResponse = await twoFAResponse.json();
          if (twoFAResponse.status !== 200 && twoFAResponse.status !== 404) {
            throw new FetchError("issue with pulling 2fa settings", {
              status: twoFAResponse.status,
              errors: twoFABody.errors,
            });
          }
          const mfaSettingIsVerified = twoFABody.is_verified ?? false;
          await identify({ authToken: token });
          await track(UnifiedAuthEvents.BasicAuthLoginComplete(username));
          setSession({
            token,
            setup2FARequired,
            passwordResetRequired,
            mfaSettingIsVerified,
          });
          const navigationOptions = {
            setup2FARequired,
            passwordResetRequired,
            mfaSettingIsVerified,
            skipSecurityCheck: mfaSettingIsVerified,
            redirectToOAuth: false,
          };
          if (enableUnifiedAccountLinking) {
            const unifiedAccountLinkingEligibility =
              await fetchUnifiedAccountLinkingEligibility(token);

            if (unifiedAccountLinkingEligibility.eligible) {
              reactRouterNavigate("/unified_account_linking/prompt");
              return;
            }
            if (
              unifiedAccountLinkingEligibility.error ===
              UnifiedProvisionPlanError.VALIDATE_2FA_REQUIRED
            ) {
              externalNavigate.toValidate2FAForUnifiedAccountLinking();
              return;
            }
          }
          removeSessionUsername();
          navigateToMako(navigationOptions);
          dispatch({ type: "LOGIN_WITH_IDENTIFIER_AND_PASSWORD_COMPLETE" });
        } catch (error: any) {
          removeSessionUsername();
          dispatch({ type: "ERROR", error: loginError(error) });
          console.error(`Error: ${error}`);
        }
      },
      [
        identify,
        track,
        setSession,
        enableUnifiedAccountLinking,
        removeSessionUsername,
        navigateToMako,
        fetchUnifiedAccountLinkingEligibility,
        externalNavigate,
        reactRouterNavigate,
      ]
    );

    /**
     * This logs a user out of all the various parts for SendGrid, it clears the session, calls auth0 if Logged in
     * Removes the session username and removes the unified user info.
     */
    const logout = useCallback(
      async (opts: LogoutOptions = {}) => {
        const { returnTo = "" } = opts;
        if (returnTo) {
          store.set(LOGOUT_REDIRECT_TO, returnTo);
        }
        if (makoToken) {
          await deleteAuthenticateToken(makoToken);
        }
        clearSession();
        removeSessionUsername();
        removeUnifiedUserInfo();
        await logoutWithAuth0({
          openUrl: (url) => {
            window.location.replace(url);
          },
          logoutParams: { returnTo: window.location.origin + `/logged-out` },
        });
      },
      [
        store,
        makoToken,
        clearSession,
        logoutWithAuth0,
        removeSessionUsername,
        removeUnifiedUserInfo,
      ]
    );

    // This function will try and fetch the session token from an access token
    const fetchTokenAndRedirect = useCallback(
      async (access_token: string, user?: User): Promise<boolean> => {
        try {
          const result = await fetchSessionToken(access_token);
          if (!result.success && result.error) {
            throw result.error;
          } else if (!result.success) {
            return false;
          }
          const authenticateBody = result.body;
          // The user has a valid auth0 access token and fetch session found them
          // navigate to mako.
          setUnifiedUserInformationInCookie(user);
          const token = authenticateBody.token ?? "";
          const passwordResetRequired = authenticateBody.password_reset_required ?? false;
          const setup2FARequired = authenticateBody.setup_2fa_required ?? false;
          let identity: Identity = { authToken: token };
          if (user?.["https://twilio.com/unified_user_id"]) {
            identity.traits = { unifiedUserId: user?.["https://twilio.com/unified_user_id"] };
          }
          await identify(identity);
          await track(UnifiedAuthEvents.Auth0LoginComplete(user));
          setSession({
            token,
            setup2FARequired,
            passwordResetRequired,
          });
          navigateToMako({
            setup2FARequired,
            passwordResetRequired,
            mfaSettingIsVerified: false,
            skipSecurityCheck: true,
            redirectToOAuth: false,
          });
          return true;
        } catch (error: any) {
          if (error.name !== "AbortError") {
            // If it's not an AbortError throw
            throw error;
          }
        }
        return true;
      },
      [setUnifiedUserInformationInCookie, identify, track, setSession, navigateToMako]
    );

    const signupUser = useCallback(
      async (access_token: string, id_token: string, appState?: AppState, user?: User) => {
        // If we are coming from unified signup then we can bypass the signup.sendgrid.com and just create the user.
        const bypassSignup = !!appState && appState["screenHint"] === "signup";
        if (bypassSignup) {
          const signupResponse = await fetchUnifiedSignup(access_token, id_token);
          if (!signupResponse || !signupResponse.ok) {
            throw loginError("Error creating unified user please contact support");
          }
          await fetchTokenAndRedirect(access_token, user);
          await track(UnifiedAuthEvents.SendGridSignupComplete(bypassSignup, user));
          return;
        }
        await track(UnifiedAuthEvents.SendGridSignupComplete(bypassSignup, user));
        setUnifiedUserInformationInCookie(user);
        externalNavigate.toUnifiedSignup();
        return;
      },
      [track, setUnifiedUserInformationInCookie, externalNavigate, fetchTokenAndRedirect]
    );

    const tryRedirectingUser = useCallback(
      async (access_token: string, id_token: string, appState?: AppState, user?: User) => {
        await acceptTeammateInvite(id_token, appState, user);

        const fromUnifiedAccountLink: boolean = Boolean(appState?.["fromUnifiedAccountLink"]);
        if (fromUnifiedAccountLink) {
          reactRouterNavigate("/unified_account_linking/confirm");
          return;
        }

        // Now try fetching again
        if (await fetchTokenAndRedirect(access_token, user)) {
          return;
        }

        // Use unified search response to find out if the user actually exists.
        const unifiedSearchResponse = await fetchUnifiedSearch(access_token, id_token);
        if (!unifiedSearchResponse.ok) {
          throw loginError("Error retrieving unified user please contact support");
        }
        // If the user is not found navigate to the signup flow.
        if (unifiedSearchResponse.status === 404) {
          await signupUser(access_token, id_token, appState, user);
          return;
        }

        const response: FetchUnifiedSearchResponse = await unifiedSearchResponse.json();
        const userEmailExists = Array.isArray(response.result) && response.result.length > 0;
        if (userEmailExists) {
          reactRouterNavigate("/login/identifier");
          return;
        }

        await signupUser(access_token, id_token, appState, user);
        return;
      },
      [reactRouterNavigate, fetchTokenAndRedirect, signupUser]
    );

    const _prepareGetAccessTokenSilently = useCallback((): GetTokenSilentlyOptions => {
      return {
        authorizationParams: {
          audience: audience,
        },
      };
    }, [audience]);

    const handleRedirectCallback = useCallback(
      async (appState?: AppState, user?: User) => {
        dispatch({ type: "HANDLE_REDIRECT_CALLBACK_STARTED" });
        removeUnifiedUserInfo();
        try {
          const options = _prepareGetAccessTokenSilently();
          const { access_token, id_token } = await getAccessTokenSilently({
            ...options,
            detailedResponse: true,
          });
          await tryRedirectingUser(access_token, id_token, appState, user);
          dispatch({ type: "HANDLE_REDIRECT_CALLBACK_COMPLETE" });
        } catch (error: unknown) {
          dispatch({ type: "ERROR", error: loginError(error) });
          console.error(`Error: ${error}`);
        }
      },
      [
        getAccessTokenSilently,
        tryRedirectingUser,
        removeUnifiedUserInfo,
        _prepareGetAccessTokenSilently,
      ]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          handleRedirectCallback: async (appState?: AppState, user?: User) => {
            await handleRedirectCallback(appState, user);
          },
        };
      },
      [handleRedirectCallback]
    );

    const navigateToGuide = useCallback(async () => {
      if (isAuthenticatedWithSendGrid) {
        await identify({ authToken: makoToken });
        removeSessionUsername();
        navigateToMako({
          setup2FARequired: false,
          passwordResetRequired: false,
          mfaSettingIsVerified: false,
          skipSecurityCheck: true,
          redirectToOAuth: false,
        });
      }
    }, [isAuthenticatedWithSendGrid, identify, makoToken, removeSessionUsername, navigateToMako]);

    const navigate = useCallback(
      async (opts: NavigateOptions) => {
        const { identifier: username, bot_detection_token = "" } = opts;
        dispatch({ type: "NAVIGATE_STARTED" });
        try {
          // Routing for SSO, Auth0, and Basic Auth
          // Build the request for the gandalf identifier endpoint
          // Call the gandalf identifier endpoint
          const result = await fetchIdentifier(username, bot_detection_token);
          if (!result.success && result.error) {
            throw result.error;
          }
          if (!result.success) {
            setSessionUsername(username);
            reactRouterNavigate("/login/password");
            dispatch({ type: "NAVIGATE_COMPLETE" });
            return;
          }
          const loginType = result.body.login_type;
          if (loginType === "mp-sso") {
            // Check username (email) against SSO if it doesn't belong to SSO, redirect to /login
            let response = await fetchSSO(username);
            let data: FetchSSOResponse = await response.json();
            if (response.status === 422 || response.status === 404) {
              dispatch({ type: "ERROR", error: loginError(bannerStatus.SSONotSetUpError.banner) });
              return;
            }
            if (response.status === 500) {
              const errorMsg =
                "Our system is currently offline. Please try again in a few minutes.";
              // Signal sciences is currently killing the http1.1 connection instead of letting it complete
              // So even when they send us a 406 allegedly it's actually coming back as a network error in jquery terms it's
              // a status code 0
              dispatch({ type: "ERROR", error: loginError(errorMsg) });
              return;
            }
            dispatch({
              type: "SSO_LOGIN_COMPLETE",
              ssoLoginInfo: {
                idp_url: data.idp_url,
                saml_request: data.saml_request,
                relay_state: data.relay_state,
              },
            });
            return;
          } else if (loginType === "cidp-oidc") {
            await loginWithAuth0({ email: username });
            dispatch({ type: "NAVIGATE_COMPLETE" });
            return;
          } else {
            setSessionUsername(username);
            reactRouterNavigate("/login/password");
            dispatch({ type: "NAVIGATE_COMPLETE" });
            return;
          }
        } catch (error: unknown) {
          dispatch({ type: "ERROR", error: loginError(error) });
        }
      },
      [loginWithAuth0, reactRouterNavigate, setSessionUsername]
    );

    const redirectToUnifiedAuthWithLinking = useCallback(
      async (linkType: UnifiedAccountLinkType, prefillEmail?: string) => {
        loginWithAuth0({
          screen_hint: linkType,
          fromUnifiedAccountLink: true,
          email: prefillEmail,
        });
      },
      [loginWithAuth0]
    );

    const skipUnifiedAccountLinking = useCallback(async () => {
      if (!isAuthenticatedWithSendGrid) {
        return;
      }
      await identify({ authToken: makoToken });
      removeSessionUsername();
      navigateToMako({
        ...navigationOptions,
        mfaSettingIsVerified: false,
        skipSecurityCheck: navigationOptions.mfaSettingIsVerified,
        redirectToOAuth: false,
      });
    }, [
      identify,
      isAuthenticatedWithSendGrid,
      makoToken,
      navigateToMako,
      navigationOptions,
      removeSessionUsername,
    ]);

    const goToLoginIfNotAuthenticatedWithSendgrid = useCallback(() => {
      if (!isAuthenticatedWithSendGrid) {
        reactRouterNavigate("/login");
      }
    }, [isAuthenticatedWithSendGrid, reactRouterNavigate]);

    const confirmUnifiedAccountLinking =
      useCallback(async (): Promise<ConfirmAccountLinkingResult> => {
        if (!makoToken || !twilioAuth0User) {
          return { success: false, error: UnifiedProvisionError.GENERAL_ERROR };
        }
        try {
          // this function throws an error when refreshing page
          // after signing up with twilio auth0.
          const { access_token, id_token } = await getAccessTokenSilently({
            authorizationParams: {
              audience,
            },
            detailedResponse: true,
          });

          const response = await fetchUnifiedProvision(makoToken, id_token);
          if (!response.success) {
            return { success: false, error: response.reason };
          }

          await fetchTokenAndRedirect(access_token, twilioAuth0User);
          return { success: true };
        } catch (error) {
          return { success: false, error: UnifiedProvisionError.GENERAL_ERROR };
        }
      }, [audience, twilioAuth0User, fetchTokenAndRedirect, getAccessTokenSilently, makoToken]);

    const isLoading = useMemo(
      () => state.isLoadingWithSendGrid || isLoadingWithAuth0,
      [state, isLoadingWithAuth0]
    );

    const contextValue = useMemo(() => {
      return {
        ...state,
        isAuthenticatedWithSendGrid,
        isAuthenticatedWithAuth0,
        isLoadingWithAuth0,
        isLoading,
        navigate,
        loginWithAuth0,
        loginWithBasicAuth,
        handleRedirectCallback,
        logout,
        navigateToGuide,
        redirectToUnifiedAuthWithLinking,
        skipUnifiedAccountLinking,
        goToLoginIfNotAuthenticatedWithSendgrid,
        confirmUnifiedAccountLinking,
        fetchUnifiedAccountLinkingEligibility,
      };
    }, [
      state,
      isAuthenticatedWithSendGrid,
      isAuthenticatedWithAuth0,
      isLoadingWithAuth0,
      isLoading,
      navigate,
      loginWithAuth0,
      loginWithBasicAuth,
      handleRedirectCallback,
      navigateToGuide,
      logout,
      redirectToUnifiedAuthWithLinking,
      skipUnifiedAccountLinking,
      goToLoginIfNotAuthenticatedWithSendgrid,
      confirmUnifiedAccountLinking,
      fetchUnifiedAccountLinkingEligibility,
    ]);
    return <context.Provider value={contextValue}>{children}</context.Provider>;
  }
);
